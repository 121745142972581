<template>
    <el-form :model="review">
        <el-form-item label  v-if="select">
            <el-radio-group v-model="review.result">
                <el-radio-button :label="false">退回</el-radio-button>
                <el-radio-button :label="true">通过</el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-form-item label>
            <el-input
                type="textarea"
                v-model="review.memo"
                :placeholder="`${review.result ? '备注' : '退回原因'}`"
                rows="5"
            ></el-input>
        </el-form-item>
        <slot></slot>
        <el-form-item label>
            <el-popconfirm title="确认提交吗！？" @confirm="doConfirm()" placement="top">
                <template #reference>
                    <el-button type="primary" :loading="isLoading">
                        {{
                            isLoading ? "处理中" : "确定"
                        }}
                    </el-button>
                </template>
            </el-popconfirm>
        </el-form-item>
    </el-form>
</template>

<script lang="ts">
import {
    inject,
    defineComponent,
    toRefs,
    ref,
    reactive,
    watch,
    toRef,
    Ref,
    PropType,
} from "vue";

export default defineComponent({
    components: {

    },
    props: {
        select: { type: Boolean, default: true },
    },
    emits: ["submit"],
    setup(props, { emit }) {
        const review = ref({
            result: true,
            memo: ""
        });
        const isLoading = ref(false);
        const doConfirm = () => {
            isLoading.value = true;
            emit("submit", review.value);
        }

        return {
            review,
            doConfirm,
            isLoading
        };
    },
});
</script>

<style scoped>
</style>